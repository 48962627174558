import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import loadable from "@loadable/component"

const Post = loadable(() => import("../components/post"))

const BlogPostTemplate = ({ data }) => {
  const isSSR = typeof window === "undefined"
  const [article, setArticle] = useState({
    post: null,
  })

  useEffect(() => {
    if (!isSSR && document.readyState === "complete") {
      setArticle({ ...data })
    }
  }, [isSSR, data])

  const { post } = article

  return <Layout>{!isSSR && !!post && <Post post={post} />}</Layout>
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      tags {
        nodes {
          name
          uri
          count
        }
      }
      featuredImage {
        node {
          altText
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
      ometa {
        articleSource
        audioFile {
          localFile {
            url
          }
        }
      }
    }
  }
`
